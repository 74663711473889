import React, { memo, useEffect, useState } from "react";
import { IconButton, InputBase, Paper } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

export const CustomizedSearchInput = memo(function CustomizedSearchInput({
  searchFilter,
  setSearchFilter,
  searchPlaceholder,
  maxWidth,
}) {
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const handleActionClick = (event) => {
    event.preventDefault();
    setSearchFilter(searchValue);
  };

  const handleClearInput = () => {
    setSearchValue("");
    setSearchFilter("");
  };

  useEffect(() => {
    if (searchFilter && searchFilter !== searchValue) {
      setSearchValue(searchFilter);
    }
  }, [searchFilter]);

  return (
    <Paper
      component="form"
      onSubmit={handleActionClick}
      sx={{
        p: "2px 4px",
        display: "flex",
        alignItems: "center",
        maxWidth: maxWidth || 325,
        border: 1,
        borderColor: theme.palette.grey[300],
      }}
    >
      <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        color="primary"
        onClick={(e) => {
          e.preventDefault();
          handleActionClick(e);
        }}
      >
        <SearchIcon />
      </IconButton>
      <InputBase
        sx={{ flex: 1 }}
        placeholder={searchPlaceholder || "Search"}
        inputProps={{ "aria-label": "search input" }}
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
        }}
        onKeyUp={(e) => {
          e.preventDefault();
          if (e.key === "Enter" || e.keyCode === 13) {
            handleActionClick(e);
          }
          if (e.key === "Escape") {
            handleClearInput();
          }
        }}
      />
      {searchValue && (
        <IconButton
          type="button"
          sx={{ p: "10px" }}
          aria-label="clear search"
          onClick={handleClearInput}
        >
          <CloseIcon />
        </IconButton>
      )}
    </Paper>
  );
});
