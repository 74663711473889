import PropTypes from "prop-types";
import React, { createContext, useEffect, useReducer } from "react";
import { useDispatch } from "store";
import { Chance } from "chance";
import { jwtDecode } from "jwt-decode";
import { LOGIN, LOGOUT } from "store/actions";
import accountReducer from "store/accountReducer";
import { openSnackbar } from "store/slices/snackbar";

import {
  setActiveUser,
  setSsoIdpUrl,
  setSsoIdpClient,
  setSsoUser,
} from "store/slices/user";
import { setSelectedClient } from "store/slices/customer";

// project imports
import { Loader } from "components/Loader/Loader";
import axios from "utils/axios";

import {
  getUserInfo,
  getUserInfoWithUrl,
  removeSession,
  refreshTokenApi,
  setSession,
  verifyToken,
  getClientByDomain,
  revokeToken,
} from "./AuthContext.helpers";
import { makeUserAuthentication } from "./makeOIDCUserManager";
import { storage } from "../../utils/helpers/storage";
import { usePostVerificationRedirect } from "../usePostVerificationRedirect";
import { SnackbarType } from "../../utils/constants/SnackbarType";
import { displayToast } from "../../store/slices/snackbar";
import { appInsights } from "../../utils/ApplicationInsightsService";
import useConfig from "../useConfig";
import { getAllReleaseInfo } from "../../store/slices/help";

const chance = new Chance();
const initialState = {
  isLoggedIn: false,
  isInitialized: false,
  user: null,
};

let refreshTimeoutId = null;

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext();

const SSOAuth = makeUserAuthentication();

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(accountReducer, initialState);
  const userDispatch = useDispatch();
  const { redirect } = usePostVerificationRedirect();
  const { onChangeMenuType } = useConfig();

  /**
   * User Input
   *  1. Domain input     > 'domain'
   *  2. getClient()      > 'region'; 'selectedClient'
   *  3. username / pass  > 'access_token'; 'refresh_token'
   *  4. handleValidUserToken()
   *
   * Refresh
   *  1. verifyToken()    > if !user_id > logout : 'access_token'; 'refresh_token'
   *  2. verifyDomain()   > if !domain > logout : 'domain'
   *  3. getClient()     > 'region'; 'selectedClient'
   *  4. handleValidUserToken()
   *
   * handleValidUserToken
   *  1. setSession       > 'Authorization'; 'locus-domain', 'locus-region' Headers
   *  2. getUserInfo()    > user
   *  3. setViewState()   > 'viewState'
   *  4. setActiveUser()  > user
   * */
  useEffect(() => {
    const init = async () => {
      try {
        const accessToken = storage.getAccessToken();
        const refreshToken = storage.getRefreshToken();
        const domain = storage.getDomain();
        if (accessToken && verifyToken(accessToken)) {
          const decoded = jwtDecode(accessToken);
          if (decoded && domain) {
            await getClient();
            await handleValidUserToken({
              access_token: accessToken,
              refresh_token: refreshToken,
            });
          }
        } else {
          dispatch({
            type: LOGOUT,
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT,
        });
      }
    };

    init();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAxiosInterceptors = () => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error?.config;

        if (error?.statusCode !== 401 || originalRequest?._retry) {
          // Display the error message using the snackbar dispatch

          dispatch(
            openSnackbar({
              open: true,
              message:
                "API Error: " + (error?.message || "Unknown error occurred."),
              variant: "alert",
              alert: {
                color: "error",
              },
              close: true,
            }),
          );

          return Promise.reject(error);
        }

        originalRequest._retry = true;

        try {
          const refreshToken = storage.getRefreshToken();
          const responseRefresh = await refreshTokenApi(
            dispatch,
            refreshToken,
            JSON.parse(storage.getActiveUser())?.username,
          );

          if (responseRefresh?.data) {
            const { access_token, refresh_token } = responseRefresh.data;
            storage.setAccessToken(access_token);
            storage.setRefreshToken(refresh_token);
            axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
            // Retry the original request with new token
            originalRequest.headers.Authorization = `Bearer ${access_token}`;
            return axios(originalRequest);
          }
        } catch (err) {
          console.error(err, "api error - cannot refresh");
          removeSession();
          dispatch({
            type: LOGOUT,
          });

          // Display the error message related to token refresh failure
          dispatch(
            openSnackbar({
              open: true,
              message:
                "Token Refresh Error: " +
                (err?.message || "Failed to refresh token."),
              variant: "alert",
              alert: {
                color: "error",
              },
              close: true,
            }),
          );
        }

        return Promise.reject(error);
      },
    );
  };

  const getClient = async () => {
    let response = await getClientByDomain(storage.getDomain());
    await userDispatch(setSelectedClient(response));
    if (response.data?.ssoIdpUrl) {
      await userDispatch(setSsoIdpUrl(response.data?.ssoIdpUrl));
    }
    if (response.data?.ssoIdpClient) {
      await userDispatch(setSsoIdpClient(response.data?.ssoIdpClient));
    }
    return response;
  };

  const setRefreshTimeout = () => {
    clearTimeout(refreshTimeoutId);
    let access_token = storage.getAccessToken();
    const currentTime = Date.now() / 1000;
    const decoded = jwtDecode(access_token);
    const tokenWillExpireInSeconds = decoded.exp - currentTime;
    if (tokenWillExpireInSeconds < 600) {
      getNewRefreshToken();
    } else {
      refreshTimeoutId = setTimeout(setRefreshTimeout, 600000); // 10 minutes
    }
  };

  const getNewRefreshToken = async () => {
    // API call logic to refresh the token
    try {
      const refreshToken = storage.getRefreshToken();
      const response = await refreshTokenApi(
        dispatch,
        refreshToken,
        JSON.parse(storage.getActiveUser())?.username,
      );
      if (response?.data) {
        const { access_token, refresh_token } = response.data;
        storage.setAccessToken(access_token);
        storage.setRefreshToken(refresh_token);
        axios.defaults.headers.common.Authorization = `Bearer ${access_token}`;
        setRefreshTimeout(); // Set up the next refresh
      }
    } catch (error) {
      userDispatch(
        displayToast(
          error?.status + " - " + error?.detail,
          SnackbarType.Negative,
        ),
      );
      logout();
    }
  };

  useEffect(() => {
    const accessToken = storage.getAccessToken();
    if (accessToken && verifyToken(accessToken)) {
      setRefreshTimeout();
    }
    // Cleanup timeout on component unmount
    return () => {
      if (refreshTimeoutId) {
        clearTimeout(refreshTimeoutId);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * @param {string} username
   * @param {string} password
   * @returns handleValidUserToken
   */
  const login = async (username, password) => {
    if (!username || !password) return;
    try {
      const decoded = storage.getAccessToken()
        ? jwtDecode(storage.getAccessToken())
        : null;
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_API}/oauth/token?grant_type=password`,
        {},
        {
          headers: {
            Authorization: `Basic ${btoa(`${username}:${password}`)}`,
            "locus-domain": decoded?.domain
              ? decoded.domain
              : storage.getDomain(),
            "locus-region": storage.getUserRegion()
              ? storage.getUserRegion()
              : storage.getRegion(),
          },
        },
      );
      let data = response?.data;
      return await handleValidUserToken(data);
    } catch (error) {
      return userDispatch(
        displayToast(
          error?.status + " - " + error?.detail,
          SnackbarType.Negative,
        ),
      );
    }
  };

  const ssoAuthorization = async (payload, token) => {
    const ssoIdpUserinfo =
      (await SSOAuth.userManager.metadataService.getUserInfoEndpoint()) ||
      storage.getSSOIdpUrl();
    const decoded = storage.getAccessToken()
      ? jwtDecode(storage.getAccessToken())
      : null;
    const response = await axios.post(
      `${
        storage.getDomain()?.toLowerCase() === "locus"
          ? payload.support_sso_authorization
          : payload.sso_authorization
      }`,
      {
        ssoIdpUrl: storage.getSSOIdpUrl(),
        ssoIdpUserinfo: ssoIdpUserinfo,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "locus-domain": decoded?.domain
            ? decoded.domain
            : storage.getDomain(),
          "locus-region": storage.getUserRegion()
            ? storage.getUserRegion()
            : storage.getRegion(),
        },
      },
    );
    userDispatch(setSsoUser(true));
    await handleValidUserToken(response.data);
    return response;
  };

  /**
   * @param {string} access_token
   * @param {string} refresh_token
   * @description
   * Gets the user's info and sets the active user
   * Redirects the user
   *
   */
  const handleValidUserToken = async ({ access_token, refresh_token }) => {
    setSession(userDispatch, access_token, refresh_token);
    setAxiosInterceptors();
    let user = jwtDecode(access_token);
    let userResponse;
    if (storage.getUserInfoEndpoint()) {
      userResponse = await getUserInfoWithUrl(
        user,
        storage.getUserInfoEndpoint(),
      );
    } else {
      userResponse = await getUserInfo(user);
    }
    await userDispatch(setActiveUser(userResponse));
    await userDispatch(getAllReleaseInfo());
    onChangeMenuType(userResponse?.theme);
    appInsights.setAuthenticatedUserContext(
      userResponse?.id,
      userResponse?.clientRole || "N/A",
    );
    // set refresh token timeout
    const accessToken = storage.getAccessToken();
    if (accessToken && verifyToken(accessToken)) {
      setRefreshTimeout();
    }
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: user,
      },
    });
    redirect();
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post("/api/account/register", {
      id,
      email,
      password,
      firstName,
      lastName,
    });
    let users = response.data;

    if (storage.getUsers() !== undefined && storage.getUsers() !== null) {
      const localUsers = storage.getUsers();
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`,
        },
      ];
    }
    storage.setUsers(JSON.stringify(users));
  };

  const logout = async () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId); // Clear timeout upon logout
    }
    let tmpSubject = storage.getActiveUser() || null;
    tmpSubject =
      tmpSubject && JSON.parse(storage.getActiveUser())?.ssoSubject
        ? JSON.parse(storage.getActiveUser())?.ssoSubject
        : null;
    if (tmpSubject) {
      try {
        let revokeUser = await ssoLogout();
        removeSession();
        dispatch({ type: LOGOUT });
        if (revokeUser) {
          await SSOAuth.userManager.signoutRedirect();
        }
      } catch (error) {
        if (process.env !== "prod") {
          console.error(error);
        }
      }
    }
    // Prevent waiting for token revocation so that user is not stuck trying to logout
    revokeToken();
    removeSession();
    dispatch({ type: LOGOUT });
  };

  const sendResetPasswordLink = async (username) => {
    if (!username) return;
    return await axios.post(
      `${process.env.REACT_APP_AUTH_API}/reset`,
      {
        username: username,
      },
      {
        headers: {
          "locus-domain": storage.getDomain(),
          "locus-region": storage.getRegion(),
        },
        params: {
          username: username,
        },
      },
    );
  };

  //may move service call
  const updateSite = async (site, id) => {
    return await axios.put(
      `${process.env.REACT_APP_SERVICES_API}/services/site/${id}`,
      site,
    );
  };

  const resetPassword = async (username, password, key, domain, region) => {
    return await axios
      .put(
        `${process.env.REACT_APP_AUTH_API}/password`,
        {
          newPassword: password,
          key: key,
          username: username,
        },
        {
          headers: {
            "locus-domain": domain,
            "locus-region": region,
          },
        },
      )
      .then((response) => {
        if (response?.data && response?.data?.message) {
          userDispatch(
            displayToast(response?.data?.message, SnackbarType.Success),
          );
        }
        return response.data;
      })
      .catch((error) => {
        if (error.code === 119) {
          userDispatch(
            displayToast(
              "The new password cannot match the current password.",
              SnackbarType.Negative,
            ),
          );
        } else {
          userDispatch(
            displayToast(
              error?.status + " - " + error?.detail,
              SnackbarType.Negative,
            ),
          );
        }
        return { error: error?.detail || error };
      });
  };

  const ssoLogout = async () => {
    return await axios
      .post(storage.getRevocationEndpoint(), {
        client_id: JSON.parse(storage.getActiveUser())?.username,
        token: storage.getRefreshToken(),
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        userDispatch(
          displayToast(
            error?.status + " - " + error?.detail,
            SnackbarType.Negative,
          ),
        );
      });
  };

  const verifyEmail = async (email, key, domain, region) => {
    return await axios
      .post(
        `${process.env.REACT_APP_AUTH_API}/email/verify`,
        {
          email: email,
          key: key,
        },
        {
          headers: {
            "locus-domain": domain,
            "locus-region": region,
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Check for link expired
        if (error.code === 214) {
          userDispatch(displayToast(error?.detail, SnackbarType.Negative));
          return { error: error?.detail || error };
        }
      });
  };

  const verifyPhone = async (username, key, domain, region) => {
    return await axios
      .post(
        `${process.env.REACT_APP_AUTH_API}/phone/verify`,
        {
          username: username,
          key: key,
        },
        {
          headers: {
            "locus-domain": domain,
            "locus-region": region,
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.error(error);
        return error;
      });
  };

  const verifyResetKey = async (username, key, domain, region) => {
    return await axios
      .post(
        `${process.env.REACT_APP_AUTH_API}/reset/verify`,
        {
          key: key,
          username: username,
        },
        {
          headers: {
            "locus-domain": domain,
            "locus-region": region,
          },
        },
      )
      .then((response) => {
        return response.data;
      })
      .catch((err) => {
        userDispatch(displayToast(err?.detail, SnackbarType.Negative));
        return { error: err.detail };
      });
  };

  const updateProfile = () => {};

  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }
  const SSOLoginInit = async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_AUTH_API}/oauth/configuration`,
      {},
      {
        headers: {
          // "locus-domain": client.domain,
          // "locus-region": client,
        },
      },
    );

    let data = response.data;
    storage.setUserInfoEndpoint(response.data.userinfo_endpoint);
    storage.setRevocationEndpoint(response.data.revocation_endpoint);

    // console.log('SSOLoginInit', data, domain, ssoIdpUrl, ssoIdpClient);
    return data;
    // // @TODO: set in reducer
    // let userManagerSettings = {
    //    //Locus dependant
    //   authority: ssoIdpUrl, //tenant //ssoIdpUrl
    //   client_id: ssoIdpClient, //tenant
    //   redirect_uri: window.location.origin + "/login/sso",
    //   post_logout_redirect_uri: window.location.origin + "/login",
    //   response_type: "code",
    //   scope: "openid email profile phone",
    //   automaticSilentRenew: true,
    //   validateSubOnSilentRenew: true,
    //   userStore: new WebStorageStateStore({ store: new InMemoryWebStorage() }),
    // }
    //
    // const userManager = new UserManager(userManagerSettings);
    // const ssoAuth = new UserAuthentication(userManager);
    // setSsoAuth(ssoAuth);
    // await ssoAuth.signinRedirect();
    // // let token = await userManager.signinCallback();
    // // console.log(token, 'token')
    // return userManager;
  };

  const makeUserAuthentication = () => {
    return SSOAuth;
  };

  return (
    <JWTContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        resetPassword,
        updateProfile,
        getClient,
        sendResetPasswordLink,
        updateSite,
        verifyResetKey,
        verifyEmail,
        verifyPhone,
        SSOLoginInit,
        makeUserAuthentication,
        ssoAuthorization,
      }}
    >
      {children}
    </JWTContext.Provider>
  );
};

JWTProvider.propTypes = {
  children: PropTypes.node,
};

export default JWTContext;
