// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import axios from "axios"; // this does not include app headers
import axiosServices from "../../utils/axios";
import { dispatch } from "../index";
import { storage } from "../../utils/helpers/storage";
import { SET_SELECTED_CLIENT, SET_SELECTED_SITE } from "../actions";
import { jwtDecode } from "jwt-decode";

// ----------------------------------------------------------------------

const initialState = {
  selectedSiteId: storage.getSite() ? JSON.parse(storage.getSite()) : null,
  selectedSite: null,
  sites: {},
};

const slice = createSlice({
  name: "site",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },
    // SET SELECTED SITE
    setSelectedSite(state, action) {
      state.selectedSite = action.payload;
    },
    setSelectedSiteId(state, action) {
      if (action.payload) {
        storage.setSite(JSON.stringify(action.payload));
      } else {
        storage.setSite("");
      }
      state.selectedSiteId = action.payload;
    },
    updateSites(state, action) {
      state.sites[action.payload.id] = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;
/**
 * @param {LocusSite} site
 */
export function setSelectedSite(site) {
  return async () => {
    if (!site) {
      dispatch(slice.actions.setSelectedSiteId(null));
      dispatch(slice.actions.setSelectedSite(null));
      return;
    }

    try {
      dispatch({
        type: SET_SELECTED_SITE,
        payload: site.id,
      });
      dispatch(slice.actions.setSelectedSiteId(site.id));

      const decoded = storage.getAccessToken()
        ? jwtDecode(storage.getAccessToken())
        : null;

      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/site/${site.id}`,
        {
          headers: {
            Authorization: `Bearer ${storage.getAccessToken()}`,
            "locus-domain": decoded?.domain,
            "locus-region": storage.getUserRegion()
              ? storage.getUserRegion()
              : storage.getRegion(),
          },
        },
      );

      if (response && response?.data) {
        dispatch(slice.actions.setSelectedSite(response.data));
        dispatch(slice.actions.updateSites(response.data));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export async function getSiteSyncTime(legacySiteId) {
  return await axiosServices.get(
    `${process.env.REACT_APP_SERVICES_API}/services/warehouse/sync/time?legacySiteId=${legacySiteId}`,
  );
}

export function getWarehouseSummary(selectedSite) {
  return async () => {
    try {
      const response = await axiosServices.get(
        `${process.env.REACT_APP_SERVICES_API}/services/warehouse/summary?site=${selectedSite?.id}`,
      );
      if (response.data) {
        dispatch(
          slice.actions.setSelectedSite({
            ...selectedSite,
            timeCompleted: {
              robots: {
                count: response.data.robots?.count || 0,
              },
              chargers: {
                count: response.data.chargers?.count || 0,
              },
              devices: {
                count: response.data.devices?.count || 0,
              },
              totals: response.data.totals || [],
              maxExecDateTimeProcessed:
                response.data.maxExecDateTimeProcessed || "N/A",
            },
          }),
        );
      }
      return response.data;
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
