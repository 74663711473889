// third-party
import { combineReducers } from "redux";

// project imports
import snackbarReducer from "./slices/snackbar";
import customerReducer from "./slices/customer";
import siteReducer from "./slices/site";
import userReducer from "./slices/user";
import robotReducer from "./slices/robot";
import deviceReducer from "./slices/device";
import chargerReducer from "./slices/charger";
import menuReducer from "./slices/menu";
import toolReducer from "./slices/tool";
import helpReducer from "./slices/help";
import analyticsReducer from "./slices/analytics";
import groupReducer from "./slices/group";
import utilsReducer from "./slices/utils";

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  snackbar: snackbarReducer,
  customer: customerReducer,
  site: siteReducer,
  user: userReducer,
  menu: menuReducer,
  robot: robotReducer,
  device: deviceReducer,
  charger: chargerReducer,
  tool: toolReducer,
  help: helpReducer,
  analytics: analyticsReducer,
  group: groupReducer,
  utils: utilsReducer,
});

export default reducer;
