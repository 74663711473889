import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { setViewState } from "../store/slices/user";
import { setSelectedSite } from "store/slices/site";
import { useDispatch, useSelector } from "../store";
import { useBackRoute } from "./useBackRoute";

const getQueryString = (searchParams) => {
  let keys = Object.keys(searchParams);
  if (keys.length === 0) return "";
  let queryString = "?";
  Object.keys(searchParams).forEach((key, i) => {
    if (i > 0) queryString += "&";
    queryString += key + "=" + searchParams[key];
  });
  return queryString;
};

/*
 * Hook to use in place of `react-router-dom`
 */
export const useLocusRouter = () => {
  let location = useLocation();
  const _navigate = useNavigate();
  const dispatch = useDispatch();
  const { goBack } = useBackRoute();
  const { viewState } = useSelector((state) => state.user);
  const { selectedClient } = useSelector((state) => state.customer);
  const { selectedSite } = useSelector((state) => state.site);
  let [searchParams, setSearchParams] = useSearchParams();
  const [currentSearchParams, setCurrentSearchParams] = useState({});

  useEffect(() => {
    let _searchParams = {};
    for (const [key, value] of searchParams) {
      _searchParams[key] = value;
    }
    setCurrentSearchParams(_searchParams);
  }, [location]);

  useEffect(() => {
    // console.log("currentSearchParams: ", currentSearchParams);
  }, [currentSearchParams]);

  /*
   * Maintains `site` query param from URL and upserts any passed params
   */
  const updateSearchParams = (params) => {
    // persist site param if present
    let _persistentParams = currentSearchParams?.site
      ? { site: currentSearchParams?.site }
      : {};

    // persist all remaining params if kiosk is present
    if (currentSearchParams?.kiosk) {
      _persistentParams = {
        ..._persistentParams,
        ...currentSearchParams,
      };
    }

    let _updatedParams = Object.keys(params).reduce((acc, key) => {
      acc[key] = params[key];
      return acc;
    }, _persistentParams);

    setSearchParams(getQueryString(_updatedParams));
  };
  /*
   * Maintains all current URL Search Params
   * @example
   *
   * Current Route: `/devices?site=12345`
   *
   * `navigate('create')`
   *
   * New Route: `/devices/create?site=12345`
   */
  const navigate = (target, options) => {
    if (options?.removeParams) {
      _navigate(target);
    } else {
      _navigate(target + getQueryString(currentSearchParams));
    }
  };

  const selectSite = async (site) => {
    const previousViewState = viewState;
    const CLIENT = `/client/${selectedClient.id}`;
    await dispatch(setViewState(site ? "site" : "client"));
    if (site) {
      await dispatch(setSelectedSite(site));
      if (previousViewState === "client") {
        // navigate(`${CLIENT}/overview-dashboard?site=${site.id}`);
        navigate(`${CLIENT}/site-info?site=${site.id}`);
      } else {
        setSearchParams(`?site=${site.id}`);
      }
    } else {
      navigate(`${CLIENT}/overview`, {
        removeParams: true,
      });
    }
  };

  const replaceDynamicRouteFromURL = (url) => {
    if (url.includes(":clientId") && selectedClient) {
      url = url.replace(":clientId", `${selectedClient.id}`);
    }
    if (url.includes(":siteId")) {
      if (selectedSite) {
        url = url.replace(":siteId", `${selectedSite.id}`);
      } else {
        url = url.replace("?site=:siteId", ``);
      }
    }

    return url;
  };

  const getHomeRoute = () => {
    if (viewState === "locus") {
      return "/locus";
    } else {
      if (selectedSite) {
        // return `/client/${selectedClient?.id}/overview-dashboard?site=${selectedSite.id}`;
        return `/client/${selectedClient?.id}/site-info?site=${selectedSite.id}`;
      }
      return `/client/${selectedClient?.id}/overview`;
    }
  };

  const getBreadcrumbRoute = (url) => {
    if (url === "/" && viewState === "locus") {
      return "/locus";
    }

    return url + getQueryString(currentSearchParams);
  };

  return {
    navigate: navigate,
    updateSearchParams: updateSearchParams,
    selectSite: selectSite,
    searchParams: searchParams,
    setSearchParams: setSearchParams,
    replaceDynamicRouteFromURL: replaceDynamicRouteFromURL,
    getHomeRoute: getHomeRoute,
    getBreadcrumbRoute: getBreadcrumbRoute,
    goBack: goBack,
  };
};
