import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "../store";
import { setLocus, setViewState } from "../store/slices/user";
import { storage } from "../utils/helpers/storage";
import { getSitesAvailableToUser } from "../routes/ProtectedRoute";
import { getCustomer } from "../store/slices/customer";
import { setSelectedSite } from "../store/slices/site";
import {
  CROSS_SITE_VIEWER,
  USER_MANAGER,
  SYSTEM_INTEGRATOR,
} from "../utils/constants/user";

/**
 * Handles redirecting post user token validation from login and page refresh
 * Sets `locus: boolean`;
 * Sets `viewState: 'locus' || 'client' || 'site'`;
 * Sets `selectedSite: 'Site'`; if provided in url query params
 * Navigates home or to provided URL
 */
export const usePostVerificationRedirect = () => {
  let location = useLocation();
  const pathname = location.pathname;
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const { activeUser, viewState } = useSelector((state) => state.user);
  const { selectedClient } = useSelector((state) => state.customer);

  const dispatch = useDispatch();

  const siteIdParam = searchParams.get("site");
  const kioskParam = searchParams.get("kiosk");

  const [doRedirect, setDoRedirect] = useState(false);

  useEffect(() => {
    const handleSiteRedirect = async () => {
      if (siteIdParam) {
        const siteToSelect = { id: siteIdParam };
        // Check if user has permission to view `site`
        let sites =
          getSitesAvailableToUser({
            sites: [siteToSelect],
            groups: activeUser?.groups,
          }).length > 0;

        if (sites) {
          await dispatch(setSelectedSite(siteToSelect));
          await dispatch(setViewState("site"));
        }
      } else {
        await dispatch(setSelectedSite(null));
        await dispatch(setViewState("client"));
      }
    };
    if (!siteIdParam && viewState !== "locus") {
      handleSiteRedirect();
    } else if (siteIdParam) {
      handleSiteRedirect(siteIdParam);
    }
  }, [siteIdParam]);

  const sendToHomePage = async () => {
    /* Redirect to home page */
    if (activeUser?.domain === "locus") {
      await dispatch(setViewState("locus"));
      navigate("/locus");
    } else if (
      activeUser?.groups?.find(
        (group) =>
          group.name === CROSS_SITE_VIEWER || group.name === SYSTEM_INTEGRATOR,
      )
    ) {
      await dispatch(setViewState("client"));
      navigate(`/client/${storage.getClientId()}/overview`);
    } else {
      if (activeUser?.groups?.length) {
        await dispatch(setViewState("site"));
        const sites = getSitesAvailableToUser({
          sites: selectedClient?.sites,
          groups: activeUser?.groups,
        });
        if (sites?.length) {
          navigate(
            `/client/${storage.getClientId()}/site-info?site=${sites[0].id}`,
          );
        } else if (
          activeUser?.groups?.find((group) => group.name === USER_MANAGER)
        ) {
          navigate(`/client/${storage.getClientId()}/users`);
        } else {
          navigate(`/client/${storage.getClientId()}/unauthorized`);
        }
      }
    }
  };

  const checkUrlForClientId = async () => {
    const url = pathname.split("/");
    if (url[1] === "client" && !kioskParam && url[2] !== selectedClient?.id) {
      await dispatch(getCustomer(url[2]));
    }
  };

  const redirect = async () => {
    if (activeUser?.domain === "locus") {
      await dispatch(setLocus(true));
    } else {
      await dispatch(setLocus(false));
    }

    await checkUrlForClientId();

    // go to missing permission page if no groups; `/kiosk` if browser is a kiosk
    if (!activeUser?.groups?.length) {
      storage.getKiosk() ? navigate("/kiosk") : navigate("/login-permissions");
    } else if (
      /* Handles navigation post login */
      pathname === "/login/sso" ||
      pathname === "/login" ||
      pathname === "/"
    ) {
      await sendToHomePage();
    }
    setDoRedirect(false);
  };

  useEffect(() => {
    if (doRedirect && activeUser) {
      redirect();
    }
  }, [doRedirect, activeUser]);

  return {
    redirect: () => setDoRedirect(true),
  };
};
